.box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: rgba(255, 255, 255, 0.4);
  transition: 0.4s ease-in-out;
  height:25rem;
  
}
.box:hover {
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.4s ease-in-out;
}
.box h4 {
  margin-top: 20px;
}
.box p {
  margin-top: 10px;
  font-size: 14px;
}
.pic {
  max-width: 400px;
}
