@import url("https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@200&display=swap");
/* body {
    background-image: url('https://c.tenor.com/-jlcuGvl9TUAAAAC/tv-old.gif');
  } */
  #home{
    background-image: url('../imgs/banner.jpg');
    background-size:cover;
    height: 600px;
  }
#welcomme {
  text-align: center;
  font-size: 144px;
  margin-bottom: 0px;
}
#welcomme:hover #Sefa {
  display: none;
}
#welcomme #Safe {
  display: none;
}
.hrow {
  min-height: 300px;
}
#welcomme:hover #Safe {
  display: inline-block;
}
.desc {
  text-align: center;
  font-size: 48px;
}
.banner{
  object-fit: cover;
  width: 100%;
  height: 400px;
}
.descp {
  padding: 30px;
  font-size: 36px;
  color: whitesmoke;
}
.inforow {
  text-align: center;
  margin: 20px 0 20px 0;
}
.hcolc65 {
  background-color: #e4d2c8;
}
.hcol4 {
  padding: 0 !important;
  margin: 0 !important;
}
/* .picframe{
  border-radius: 5%;
  margin-top: 15px;
  height: 370px;
  width: 610px;
  background-color: rgba(0,0,0,0.5);
} */
#space {
  object-fit: cover;
  width: 100%;
  min-height: 350px;
  /* border-radius: 3%;
  margin-top: 10px;
  margin-left: 10px;
  height: 350px;
  width: 590px; */
}
.hcol8f7 {
  background-color: #8f746d;
  color: whitesmoke;
}
.hcol8f7 h4{
  text-align: center;
  transform: translateY(20%);
}
body {
  background-color: #f6f6f6;
  font-family: "Chivo Mono", monospace;
}

@media screen and (max-width: 992px) {
  #welcomme {
    font-size: 72px;
  }
  .desc {
    font-size: 24px;
  }
  .hrow {
    min-height: 200px;
    height: auto !important;
  }
  .hcol8f7 h4 {
    margin: 5%;
    text-align: center;
    font-size: 16px;
  transform: translateY(0%);
  margin-top: 30px;
  margin-bottom: 50px;
  }
  .descp {
    color: whitesmoke;
    font-size: 18px;
  }
  #space {
    height: 200px;
    min-height: none;
  }
}
